import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AdministrationApiConfiguration,
  AdministrationApiPortalService,
  AdministrationApiUserService,
  AdministrationApiUserStorageService,
  BillingApiConfiguration,
  ConfigExportService,
  ConfigResourceService,
  CustomConfigService,
  DatasourceApiConfiguration,
  ErrorDetectionApiConfiguration,
  FFUFKeycloakUsersApiRoleService,
  FFUFKeycloakUsersApiUserService,
  FFUFPasswordApiService,
  FFUFUsersApiDeviceService,
  FFUFUsersApiProfitcenterService,
  FFUFUsersApiUserService,
  FfufAutomationActionApiService,
  FfufAutomationApiService,
  FfufAutomationConditionApiService,
  FfufAutomationEventApiService,
  FfufAutomationLogsApiService,
  FfufAutomationRuleApiService,
  FfufAutomationSettingApiService,
  FfufAutomationTriggerApiService,
  FfufAutomationUserApiService,
  FfufLogfilesApiService,
  FfufQuotaStatisticsApiService,
  FilestorageApiFileMetadataService,
  FilestorageApiPlantStorageDetailsService,
  IntervalImportApiEventDayService,
  IntervalImportApiLastComponentEventCollectionService,
  IntervalImportApiPowerControlService,
  LCDService,
  LandingPageEntity,
  NotificationService,
  PortalEntity,
  ProfitcenterField,
  RawdataService,
  RawdataTaskStateService,
  RemoteCommandService,
  ReportingApiConfiguration,
  SolarLogInterchangeActivePowerService,
  SolarLogInterchangeComponentConfigurationService,
  SolarLogInterchangeComponentDetectionService,
  SolarLogInterchangePlantParametersService,
  SolarLogInterchangeReactivePowerService,
  SolarLogInterchangeTelecontrolConfigurationService,
  TimelineApiConfiguration,
  UserMe,
  UserStorageEntity,
  VisualisationApiConfiguration,
  WeatherForecastApiWeatherDataService,
  WidgetsApiConfiguration,
  WidgetsLandingPageService
} from '@sds/api';
import {
  ConfigProperty,
  ConfigService,
  Language,
  LocaleStore,
  PassPortalFields,
  PortalSettingsStore,
  Theme,
  UserStore,
  handleJSON
} from '@sds/shared';
import { UserSetting, UserSettingsStore } from '@sds/user-settings';
import { browserTracingIntegration, init, replayIntegration, setUser } from '@sentry/angular';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { Observable, catchError, filter, forkJoin, from, map, of, switchMap, tap, zip } from 'rxjs';
import { authConfig } from '../oauth2.config';

export function initializerFactory(oauthInitializerService: InitializerService, configService: ConfigService) {
  return () => from(configService.loadConfigurationData()).pipe(switchMap(() => oauthInitializerService.initialize()));
}

@Injectable({
  providedIn: 'root'
})
export class InitializerService {
  constructor(
    private readonly router: Router,
    private readonly localeStore: LocaleStore,
    private readonly userStore: UserStore,
    private readonly userSettingsStore: UserSettingsStore,
    private readonly portalSettingsStore: PortalSettingsStore,
    private readonly configService: ConfigService,
    private readonly oauthService: OAuthService,
    private readonly oAuthStorage: OAuthStorage,
    private readonly portalService: AdministrationApiPortalService,
    private readonly passUserApiService: FFUFUsersApiUserService,
    private readonly passDeviceService: FFUFUsersApiDeviceService,
    private readonly passKeycloakUsersService: FFUFKeycloakUsersApiUserService,
    private readonly passKeycloakRolesService: FFUFKeycloakUsersApiRoleService,
    private readonly passProfitcenterApiService: FFUFUsersApiProfitcenterService,
    private readonly weatherDataService: WeatherForecastApiWeatherDataService,
    private readonly lcdService: LCDService,
    private readonly passPassowordApiService: FFUFPasswordApiService,
    private readonly eventCollectionService: IntervalImportApiEventDayService,
    private readonly userStorageService: AdministrationApiUserStorageService,
    private readonly ffufLogfilesApiService: FfufLogfilesApiService,
    private readonly ffufQuotaStatisticsApiService: FfufQuotaStatisticsApiService,
    private readonly ffufAutomationActionApiService: FfufAutomationActionApiService,
    private readonly ffufAutomationApiService: FfufAutomationApiService,
    private readonly ffufAutomationConditionApiService: FfufAutomationConditionApiService,
    private readonly ffufAutomationEventApiService: FfufAutomationEventApiService,
    private readonly ffufAutomationLogsApiService: FfufAutomationLogsApiService,
    private readonly ffufAutomationRuleApiService: FfufAutomationRuleApiService,
    private readonly ffufAutomationSettingApiService: FfufAutomationSettingApiService,
    private readonly ffufAutomationTriggerApiService: FfufAutomationTriggerApiService,
    private readonly ffufAutomationUserApiService: FfufAutomationUserApiService,
    private readonly configExport: ConfigExportService,
    private readonly lastComponentEventCollectionService: IntervalImportApiLastComponentEventCollectionService,
    private readonly intervalImportApiPowerControlService: IntervalImportApiPowerControlService,
    private readonly ffufNotificationService: NotificationService,
    private readonly widgetsLandingPageService: WidgetsLandingPageService,
    private readonly remoteCommandService: RemoteCommandService,
    private readonly customConfigService: CustomConfigService,
    private readonly configResourceService: ConfigResourceService,
    private readonly rawDataService: RawdataService,
    private readonly rawDataTaskStateService: RawdataTaskStateService,
    private readonly filestorageApiFileMetadataService: FilestorageApiFileMetadataService,
    private readonly filestorageApiPlantStorageDetailsService: FilestorageApiPlantStorageDetailsService,
    private readonly solarLogInterchangeComponentDetectionService: SolarLogInterchangeComponentDetectionService,
    private readonly solarLogInterchangeComponentConfigurationService: SolarLogInterchangeComponentConfigurationService,
    private readonly solarLogInterchangeActivePowerService: SolarLogInterchangeActivePowerService,
    private readonly solarLogInterchangeReactivePowerService: SolarLogInterchangeReactivePowerService,
    private readonly solarLogInterchangePlantParametersService: SolarLogInterchangePlantParametersService,
    private readonly solarLogInterchangeTelecontrolConfigurationService: SolarLogInterchangeTelecontrolConfigurationService,
    private readonly administrationApiUserService: AdministrationApiUserService,
    private readonly administrationApiConfiguration: AdministrationApiConfiguration,
    private readonly billingApiConfiguration: BillingApiConfiguration,
    private readonly datasourceApiConfiguration: DatasourceApiConfiguration,
    private readonly errorDetectionApiConfiguration: ErrorDetectionApiConfiguration,
    private readonly reportingApiConfiguration: ReportingApiConfiguration,
    private readonly timelineApiConfiguration: TimelineApiConfiguration,
    private readonly visualisationApiConfiguration: VisualisationApiConfiguration,
    private readonly widgetsApiConfiguration: WidgetsApiConfiguration
  ) {}

  initialize(): Observable<unknown> {
    this.resolveApiServiceRootUrls(this.configService);
    this.oauthService.configure({
      ...authConfig,
      clientId: this.configService.get(ConfigProperty.OauthClientId),
      issuer: this.configService.get(ConfigProperty.Issuer),
      tokenEndpoint: this.configService.get(ConfigProperty.TokenEndpoint),
      userinfoEndpoint: this.configService.get(ConfigProperty.UserInfoEndpoint),
      loginUrl: this.configService.get(ConfigProperty.LoginUrl),
      logoutUrl: this.configService.get(ConfigProperty.LogoutUrl)
    });

    if (window.location.search.includes('token') && !this.oAuthStorage.getItem('access_token')) {
      const accessToken = window.location.search
        .substring(window.location.search.indexOf('token'))
        .replace('token=', '');
      this.oAuthStorage.setItem('access_token', accessToken);
    }

    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      tap(() => this.oauthService.setupAutomaticSilentRefresh()),
      filter(() => {
        return this.oauthService.hasValidAccessToken();
      }),
      switchMap(() => zip([this.loadUser(), this.loadUserSettings()])),
      map(([user]) => user as UserMe),
      filter(user => !!user),
      switchMap(user => this.loadCurrentPortal(user.currentPortalId ?? '')),
      switchMap(portal => this.loadPortalSettings(portal.ffufId ?? 0)),
      tap(() => {
        if (this.userStore.user()) {
          this.initSentry(this.configService, this.userStore.user()!);
        }
      })
    );
  }

  private resolveApiServiceRootUrls(configService: ConfigService) {
    this.administrationApiConfiguration.rootUrl = configService.get(ConfigProperty.AdministrationApi);
    this.billingApiConfiguration.rootUrl = configService.get(ConfigProperty.BillingApi);
    this.datasourceApiConfiguration.rootUrl = configService.get(ConfigProperty.DatasourceApi);
    this.errorDetectionApiConfiguration.rootUrl = configService.get(ConfigProperty.ErrorDetectionApi);
    this.reportingApiConfiguration.rootUrl = configService.get(ConfigProperty.ReportingApi);
    this.timelineApiConfiguration.rootUrl = configService.get(ConfigProperty.TimelineApi);
    this.visualisationApiConfiguration.rootUrl = configService.get(ConfigProperty.VisualisationApi);
    this.widgetsApiConfiguration.rootUrl = configService.get(ConfigProperty.WidgetsApi);
    this.ffufNotificationService.rootUrl = configService.get(ConfigProperty.NotificationsApi);
    this.passUserApiService.rootUrl = configService.get(ConfigProperty.UserApiUrl);
    this.passDeviceService.rootUrl = configService.get(ConfigProperty.UserApiUrl);
    this.passProfitcenterApiService.rootUrl = configService.get(ConfigProperty.UserApiUrl);
    this.passKeycloakRolesService.rootUrl = configService.get(ConfigProperty.PasswordApiUrl);
    this.passKeycloakUsersService.rootUrl = configService.get(ConfigProperty.PasswordApiUrl);
    this.weatherDataService.rootUrl = configService.get(ConfigProperty.WeatherDataApi);
    this.lcdService.rootUrl = configService.get(ConfigProperty.SolarLogAdapterApi);
    this.passPassowordApiService.rootUrl = configService.get(ConfigProperty.PasswordApiUrl);
    this.ffufLogfilesApiService.rootUrl = configService.get(ConfigProperty.LogfileApiUrl);
    this.ffufQuotaStatisticsApiService.rootUrl = configService.get(ConfigProperty.QuotaApiUrl);
    this.ffufAutomationActionApiService.rootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    this.ffufAutomationApiService.rootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    this.ffufAutomationConditionApiService.rootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    this.ffufAutomationEventApiService.rootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    this.ffufAutomationLogsApiService.rootUrl = configService.get(ConfigProperty.AutomationLogsApiUrl);
    this.ffufAutomationRuleApiService.rootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    this.ffufAutomationSettingApiService.rootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    this.ffufAutomationTriggerApiService.rootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    this.ffufAutomationUserApiService.rootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    this.eventCollectionService.rootUrl = configService.get(ConfigProperty.IntervalImport);
    this.configExport.rootUrl = configService.get(ConfigProperty.SolarLogAdapterApi);
    this.customConfigService.rootUrl = configService.get(ConfigProperty.SolarLogAdapterApi);
    this.lastComponentEventCollectionService.rootUrl = configService.get(ConfigProperty.IntervalImport);
    this.intervalImportApiPowerControlService.rootUrl = configService.get(ConfigProperty.IntervalImport);
    this.remoteCommandService.rootUrl = configService.get(ConfigProperty.SolarLogAdapterApi);
    this.configResourceService.rootUrl = configService.get(ConfigProperty.SolarLogAdapterApi);
    this.rawDataService.rootUrl = configService.get(ConfigProperty.SolarLogAdapterApi);
    this.rawDataTaskStateService.rootUrl = configService.get(ConfigProperty.SolarLogAdapterApi);
    this.filestorageApiFileMetadataService.rootUrl = configService.get(ConfigProperty.FilestorageApi);
    this.filestorageApiPlantStorageDetailsService.rootUrl = configService.get(ConfigProperty.FilestorageApi);
    this.solarLogInterchangeComponentDetectionService.rootUrl = configService.get(
      ConfigProperty.SolarLogInterchangeApi
    );
    this.solarLogInterchangeComponentConfigurationService.rootUrl = configService.get(
      ConfigProperty.SolarLogInterchangeApi
    );
    this.solarLogInterchangeActivePowerService.rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
    this.solarLogInterchangeReactivePowerService.rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
    this.solarLogInterchangePlantParametersService.rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
    this.solarLogInterchangeTelecontrolConfigurationService.rootUrl = configService.get(
      ConfigProperty.SolarLogInterchangeApi
    );
  }

  private initSentry(configService: ConfigService, user: UserMe) {
    const sentryUrl: string | null = configService.get(ConfigProperty.SentryDns);
    if (sentryUrl?.length) {
      setUser({ userId: user.id, email: user.primaryMail, username: user.displayName });
      init({
        dsn: sentryUrl,
        environment: window.location.hostname,
        release: `enerest-v4@${configService.get(ConfigProperty.VersionNumber) ?? '0.0.0'}`,
        replaysOnErrorSampleRate: 1,
        tracePropagationTargets: [window.location.origin, configService.get(ConfigProperty.BaseApiUrl)],
        tracesSampleRate: 1,
        ignoreTransactions: ['/print/report/:reportId/'],
        integrations: [
          browserTracingIntegration(),
          replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true
          })
        ],
        beforeSend: (event, hint) => {
          const response = hint.originalException as HttpErrorResponse;

          if (response && response.status && [401, 403, 404].includes(response.status)) {
            return null;
          }
          return event;
        }
      });
    }
  }

  private loadUser(): Observable<UserMe | null> {
    return this.administrationApiUserService.getMeUserItem({ id: 'me' }).pipe(
      map((user: UserMe) => {
        user.languageId = user.languageId!.replace('_', '-') as Language;
        return user;
      }),
      map(user => {
        if (user.languageId === Language.LO_US) {
          user.languageId = Language.LOL_US;
        }
        return user;
      }),
      tap(user => {
        this.userStore.setUser(user);
        this.localeStore.setLocale(user.languageId);
      }),
      catchError(error => {
        this.router.navigate(['oauth-error'], { queryParams: { message: error?.message, status: error?.status } });
        return of(null);
      })
    );
  }

  private loadUserSettings(): Observable<UserStorageEntity[]> {
    return forkJoin([
      this.userStorageService.getUserStorageCollection().pipe(catchError(() => of([] as UserStorageEntity[]))),
      this.widgetsLandingPageService.getLandingPageCollection().pipe(catchError(() => of([] as LandingPageEntity[])))
    ]).pipe(
      map(([settings, landingPage]) => {
        if (landingPage.length) {
          const { id, pinboardId } = landingPage[0];
          if (pinboardId) {
            settings.push({
              id,
              name: 'landingPage',
              type: 'landingPage',
              values: [pinboardId]
            });
          }
        }
        return settings;
      }),
      tap(settings => {
        const temperatureItem: UserStorageEntity | undefined = settings.find(
          s => s.type === UserSetting.TemperatureUnit
        );
        const temperature: UserStorageEntity = temperatureItem ?? this.userSettingsStore.temperatureEntity();
        const landingPageItem: UserStorageEntity | undefined = settings.find(s => s.type === UserSetting.LandingPage);
        const landingPage: LandingPageEntity | null = landingPageItem
          ? {
              id: landingPageItem.id,
              pinboardId: landingPageItem.values[0] //the pinboard id is always the first value of the array.
            }
          : null;
        const themeItem: UserStorageEntity | undefined = settings.find(s => s.type === UserSetting.Theme);
        const theme =
          themeItem?.name && Object.values(Theme).includes(themeItem?.name as Theme)
            ? themeItem
            : this.userSettingsStore.themeEntity();
        const analysisFilters: UserStorageEntity | undefined =
          settings.find(s => s.type === UserSetting.AnalysisOverviewFilter) ??
          this.userSettingsStore.analysisFiltersEntity();
        this.userSettingsStore.setLandingPageEntity(landingPage);
        this.userSettingsStore.setTemperatureUnitEntity(temperature);
        this.userSettingsStore.setThemeEntity(theme);
        this.userSettingsStore.setAnalysisFiltersEntity(analysisFilters);
      })
    );
  }

  private loadCurrentPortal(id: string): Observable<PortalEntity> {
    return this.portalService.getPortalItem({ id }).pipe(tap(portal => this.portalSettingsStore.setPortal(portal)));
  }

  private loadPortalSettings(ffufId: number): Observable<ProfitcenterField[]> {
    return this.passProfitcenterApiService
      .getFieldsByProfitcenterProfitcenterField({
        profitcenterId: ffufId
      })
      .pipe(
        map(profitCenterFields =>
          profitCenterFields.filter(
            field =>
              Object.values(PassPortalFields)
                .map(key => key.toLowerCase())
                .indexOf(field.fieldId.toLowerCase()) > -1
          )
        ),
        tap(fields => {
          const portalColors = fields.find(s => s.fieldId === PassPortalFields.PortalColors);
          if (portalColors) {
            const parsed = handleJSON<{ lightPrimaryColor: string; darkPrimaryColor: string }>(
              portalColors.fieldValue!
            );
            if (parsed && parsed.darkPrimaryColor && parsed.lightPrimaryColor) {
              this.portalSettingsStore.setThemeColors(portalColors.id!, {
                darkPrimaryColor: parsed.darkPrimaryColor,
                lightPrimaryColor: parsed.lightPrimaryColor
              });
            }
          }
          const automaticPlantAssignation = fields.find(f => f.fieldId === PassPortalFields.AutomaticPlantAssignation);
          if (automaticPlantAssignation) {
            this.portalSettingsStore.setAutomaticPlantAssignation(
              automaticPlantAssignation.id!,
              automaticPlantAssignation.fieldValue === 'true'
            );
          }
        })
      );
  }
}
